<template>
  <div>
    <el-card>
      <div slot="header" class="clearfix">
        <span>当前课程：{{currentCourse.courseName}}</span>
      </div>
      <div>
        <el-table :data="[currentCourse]" style="width: 100%">
          <el-table-column prop="courseName" label="名称">
          </el-table-column>
          <el-table-column prop="title" label="标题">
          </el-table-column>
          <el-table-column prop="description" label="详情描述">
          </el-table-column>
          <el-table-column align="right" label="操作">
            <template slot-scope="scope">
              <el-button size="mini" type="primary" @click="handleEdit(scope.row)" icon="el-icon-edit">修改</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-card>
    <br>
    <el-row :gutter="20">
      <el-col :span="14">
        <div class="grid-content bg-purple">
          <el-card>
            <div slot="header" class="clearfix">
              <span>课时管理</span>
              <el-button type="primary" style="float: right" size="mini" @click="handleAddLesson()"
                icon="el-icon-circle-plus-outline">添加课时</el-button>
            </div>
            <div>
              <el-input clearable style="width:50%;float:right" type="text" v-model="searchLesson"
                placeholder="输入课时名称检索">
              </el-input>
              <el-table style="width: 100%"
                :data="currentLessons.filter(data => !searchLesson || data.lessonName.toLowerCase().includes(searchLesson.toLowerCase()))">
                <el-table-column prop="lessonName" label="名称" sortable>
                </el-table-column>
                <el-table-column prop="title" label="标题">
                </el-table-column>
                <el-table-column align="right" label="操作">
                  <template slot-scope="scope">
                    <el-button size="mini" type="warning" @click="handleFeedback(scope.row)" icon="el-icon-data-line">反馈
                    </el-button>
                    <el-button size="mini" type="primary" @click="handleEditLesson(scope.row)" icon="el-icon-edit">修改
                    </el-button>
                    <el-button size="mini" type="danger" @click="handleDeleteLesson(scope.row)"
                      icon="el-icon-circle-close">删除</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-card>
        </div>
      </el-col>
      <el-col :span="10">
        <div class="grid-content bg-purple">
          <el-card>
            <div slot="header" class="clearfix">
              <span>学生管理</span>
              <el-button type="primary" style="float: right" size="mini" @click="handleAddStudent()"
                icon="el-icon-circle-plus-outline">添加学生</el-button>
            </div>
            <div>
              <el-input clearable style="width:50%;float:right" type="text" v-model="searchStudent"
                placeholder="输入课时名称检索">
              </el-input>
              <el-table style="width: 100%"
                :data="currentStudents.filter(data => !searchStudent || data.studentName.toLowerCase().includes(searchStudent.toLowerCase()))">
                <el-table-column prop="studentName" label="姓名" sortable>
                </el-table-column>
                <el-table-column prop="phoneNumber" label="电话">
                </el-table-column>
                <el-table-column align="right" label="操作">
                  <template slot-scope="scope">
                    <el-button size="mini" type="danger" @click="handleDeleteStudent(scope.row)"
                      icon="el-icon-circle-close">删除</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-card>
        </div>
      </el-col>
    </el-row>
    <CoursesModel v-model="showEditModel" :data="editForm" @submit="coursesSubmit" />
    <StudentModel v-model="showAddStudentModel" @submit="studentSubmit" />
    <FeedbackModel v-model="showFeedbackModel" :lessonId="lessonId" />
  </div>
</template>

<script>
  import CoursesModel from './CoursesModel'
  import StudentModel from './StudentModel'
  import FeedbackModel from './FeedbackModel'
  import {
    createNamespacedHelpers
  } from 'vuex'
  const courses = createNamespacedHelpers('courses')
  const feedback = createNamespacedHelpers('feedback')
  const admin = createNamespacedHelpers('admin')

  export default {
    name: "CoursesManagement",
    components: {
      CoursesModel,
      StudentModel,
      FeedbackModel,
    },
    data() {
      return {
        editForm: {},
        addStudentForm: {},
        showEditModel: false,
        showAddStudentModel: false,
        showFeedbackModel: false,
        searchLesson: '',
        searchStudent: '',
        lessonId: null,
      }
    },
    computed: {
      ...courses.mapGetters(['allCourses', 'currentLessons', 'currentStudents']),
      ...admin.mapGetters(['userInfo']),
      currentCourse() {
        let gotoHome = Object.prototype.hasOwnProperty.call(this.$route.params, 'data')
        gotoHome ? this.getCourseDetailById(this.$route.params.data.id) : null
        return gotoHome ? this.allCourses.find(x => x.id == this.$route.params.data.id) : {}
      },
    },
    watch: {

    },
    methods: {
      ...courses.mapActions(['editCourses', 'getCurrentLessons', 'deleteLessons',
        'getCurrentStudents', 'addStudents', 'deleteStudents', 'getCourseDetailById'
      ]),
      ...feedback.mapActions(['getFeedback']),
      handleEdit(data) {
        this.showEditModel = true
        this.editForm = JSON.parse(JSON.stringify(data))
      },
      handleAddLesson() {
        this.$router.push({
          name: 'LessonsModel',
          params: {
            courseId: this.$route.params.name,
            courseData: this.$route.params.data,
          }
        })
      },
      handleEditLesson(data) {
        this.$router.push({
          name: 'LessonsModel',
          params: {
            courseId: this.$route.params.name,
            courseData: this.$route.params.data,
            lessonData: JSON.parse(JSON.stringify(data)),
          }
        })
      },
      handleFeedback(data) {
        this.showFeedbackModel = true
        this.getFeedback({
          instructorId: this.userInfo.instructorId,
          lessonId: data.id
        })
        this.lessonId = data.id
      },
      handleDeleteLesson(data) {
        this.$confirm(`确认删除${data.lessonName}?`, '删除提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true
        }).then(() => {
          this.deleteLessons(data.id).then(() => {
            this.$message({
              type: 'success',
              message: '删除成功'
            })
          }).catch(() => {
            this.$message.error('删除失败')
          })
        }).catch(() => {
          this.$message('取消删除')
        })
      },
      handleAddStudent() {
        this.showAddStudentModel = true
        this.addStudentForm = {}
      },
      handleDeleteStudent(data) {
        this.$confirm(`确认删除${data.studentName}?`, '删除提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true
        }).then(() => {
          let courseId = this.currentCourse.id
          this.deleteStudents({
            studentIds: [data.id],
            courseId: courseId
          }).then(() => {
            this.$message({
              type: 'success',
              message: '删除成功'
            })
          }).catch(() => {
            this.$message.error('删除失败')
          })
        }).catch(() => {
          this.$message('取消删除')
        })
      },
      coursesSubmit(form) {
        this.editForm = form
        this.editForm.InstructorId = this.userInfo.instructorId
        this.submitEdit()
      },
      submitEdit() {
        this.editCourses(this.editForm).then(() => {
          this.$message({
            type: 'success',
            message: '修改成功'
          })
          this.showEditModel = false
        }).catch(() => {
          this.$message.error('修改失败')
          this.showEditModel = false
        })
      }, 
      studentSubmit(form) {
        this.addStudentForm = form
        this.submitAddStudent()
      },
      submitAddStudent() {
        let data = {
          courseId: this.currentCourse.id,
          studentIds: this.addStudentForm.studentList
        }
        this.addStudents(data).then(() => {
          this.$message({
            type: 'success',
            message: '添加成功'
          })
          this.showAddStudentModel = false
        }).catch(() => {
          this.$message.error('添加失败')
          this.showAddStudentModel = false
        })
      }
    },
    beforeCreate() {
      if (!this.$route.params.data) {
        this.$router.push({
          path: '/'
        })
      }
    },    
    mounted() {},
  }
</script>
<style lang="stylus">
  .el-textarea__inner {
    resize: none;
  }
</style>