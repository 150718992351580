<template>
  <Dialog v-model="formValue" :title="title" :width="width">
    <template v-slot:text>
      <el-form :model="form" status-icon ref="form" label-width="100px">
        <el-form-item label="选择学生" prop="student">
          <el-select v-model="form.student" placeholder="请选择" filterable style="width:100%" icon="el-icon-user-solid">
            <el-option v-for="item in currentStudents" :key="item.id" :label="item.studentName" :value="item.id"
              :style="hasFeedbacked(item)?'background-color:#BCF59F':''">
              <span style="float: left">{{ item.studentName }}</span>
              <span v-if="hasFeedbacked(item)" style="float: right; color: #8492a6; font-size: 13px">已反馈</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="反馈学生" prop="feedback">
          <quill-editor ref="text" v-model="form.feedback" :options="editorOption" />
        </el-form-item>
        <el-form-item label="反馈评分" prop="feedback">
          <el-row>
            <el-col :span="12" v-for="i in form.performances" :key="i.performanceType" style="padding:5px 5px 0 0">
              <el-input type="number" placeholder="请打分数0 - 100" v-model="i.points" @input="change($event)">
                <template slot="prepend">{{i.performanceType}}</template>
              </el-input>
            </el-col>
          </el-row>

        </el-form-item>
      </el-form>
    </template>
    <template v-slot:actions>
      <el-button @click="formValue = false">关闭</el-button>
      <el-button type="primary" style="float:left;" @click="handleSendFeedbackMessage()" :disabled="!(form.student)" icon="el-icon-bell">发送通知</el-button>
      <el-button type="primary" @click="submit()" :disabled="!canSubmit" :icon="buttonIcon">{{ buttonString }}
      </el-button>
    </template>
  </Dialog>
</template>

<script>
  import Dialog from './Dialog'
  import {
    createNamespacedHelpers
  } from 'vuex'
  import 'quill/dist/quill.core.css'
  import 'quill/dist/quill.snow.css'
  import 'quill/dist/quill.bubble.css'
  import {
    quillEditor
  } from 'vue-quill-editor'
  const feedback = createNamespacedHelpers('feedback')
  const courses = createNamespacedHelpers('courses')
  const admin = createNamespacedHelpers('admin')
  const subscribeMessage = createNamespacedHelpers('subscribeMessage')

  export default {
    props: {
      value: {
        type: Boolean,
        required: true,
      },
      lessonId: {
        type: Number,
      },
    },
    components: {
      Dialog,
      quillEditor,
    },
    data() {
      return {
        formValue: false,
        width: '70%',
        buttonString: '提交',
        buttonIcon: 'el-icon-circle-check',
        editorOption: {
          placeholder: "请在这里输入",
        },
        form: {
          student: null,
          feedback: '',
          performances: []
        },
        feedbackId: null,
        scoringItem: [{
          name: '英语词汇量',
          points: null,
        }, {
          name: '简单造句能力',
          points: null,
        }, {
          name: '自然拼读能力',
          points: null,
        }, {
          name: '发音标准程度',
          points: null,
        }, {
          name: '阅读理解能力',
          points: null,
        }, {
          name: '朗读句子连贯性',
          points: null,
        }],
      }
    },
    computed: {
      ...feedback.mapGetters(['currentFeedback', 'performanceTypeList']),
      ...courses.mapGetters(['currentStudents']),
      ...admin.mapGetters(['userInfo']),
      title() {
        return '课时反馈'
      },
      canSubmit() {
        return this.form.student && this.form.feedback
      },
      isEdit() {
        return !!this.currentFeedback.find(x => x.studentId == this.form.student)
      },
    },
    watch: {
      value(v) {
        this._updateFormValue(v)
      },
      formValue(v) {
        this._emitChange(v)
      },
      'form.student'(v) {
        let target = this.currentFeedback.find(x => x.studentId == v)
        if (target) {
          this.form.feedback = target.content
          this.form.performances = target.performances
          this.formatData()
          this.feedbackId = target.id
          this.buttonString = '修改'
          this.buttonIcon = 'el-icon-edit'
        } else {
          this.form.feedback = ''
          this.form.performances = []
          this.addPerformances()
          this.buttonString = '提交'
          this.buttonIcon = 'el-icon-circle-check'
        }
      }
    },
    methods: {
      ...feedback.mapActions(['postFeedback', 'getPerformanceTypeList']),
      ...subscribeMessage.mapActions(['sendLessonFeedbackMessage']),
      fetchData() {
        if(!this.performanceTypeList || !this.performanceTypeList.length) {
          this.getPerformanceTypeList().then(() => {
            this.formatData()
          })
        }
      },
      addPerformances() {
        this.form.performances = []
        this.performanceTypeList.forEach(element => {
          this.form.performances.push({
            id: 0, 
            assessmentId: 0, 
            performanceType: element.performanceTypeName, 
            points: 0
          })
        });
      },
      formatData() {
        this.performanceTypeList.forEach(element => {
          if(!this.form || !this.form.performances){
            return
          }
          var matched = this.form.performances.find(x => x.performanceType == element.performanceTypeName)
          if(!matched){
            this.form.performances.push({
              id: 0, 
              assessmentId: 0, 
              performanceType: element.performanceTypeName, 
              points: 0
            })
          }
          this.$forceUpdate()
        });
      },
      change(e){
        this.$forceUpdate()
      },
      clearAll() {
        this.form = {
          student: null,
          feedback: '',
          performances: []
        }
        this.feedbackId = null
      },
      _updateFormValue(v) {
        this.formValue = v
      },
      _emitChange(v) {
        this.$emit('input', v)
        if (!v) {
          this.clearAll()
        }
      },
      hasFeedbacked(item) {
        return !!this.currentFeedback.find(x => x.studentId == item.id)
      },
      submit() {
        let data = {
          Id: this.isEdit ? this.feedbackId : 0,
          InstructorId: this.userInfo.instructorId,
          LessonId: this.lessonId,
          StudentId: this.form.student,
          Content: this.form.feedback,
          isEdit: this.isEdit,
          Performances: this.form.performances.map(x => {
            return {
              Id: x.id ? x.id : 0,
              AssessmentId: this.isEdit ? this.feedbackId : 0,
              PerformanceType: x.performanceType,
              Points: x.points ? x.points : 0,
            }
          })
        }
        this.postFeedback(data).then(() => {
          this.$message({
            type: 'success',
            message: '反馈成功'
          })
        }).catch(() => {
          this.$message.error('反馈失败')
        })
      },
      handleSendFeedbackMessage() {
        this.sendLessonFeedbackMessage({
          lessonId: this.lessonId,
          studentId: this.form.student
        }).then(() => {
          this.$message({
            type: 'success',
            message: '发送成功'
          })
        }).catch(() => {
          this.$message.error('发送失败')
        })
      },
    },
    mounted() {
      this._updateFormValue(this.value)
      this.fetchData()
    },
  }
</script>

<style scoped>

</style>