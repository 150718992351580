<template>
  <Dialog v-model="formValue" :title="title" :width="width">
    <template v-slot:text>
      <el-form :model="form" status-icon ref="form" label-width="100px">
        <el-form-item label="选择学生" prop="studentList">
          <el-select v-model="form.studentList" multiple placeholder="请选择" filterable style="width:100%">
            <el-option v-for="item in activeStudents" :key="item.id" :label="item.studentName" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </template>
    <template v-slot:actions>
      <el-button @click="formValue = false">取消</el-button>
      <el-button type="primary" @click="submit()" :disabled="!canSubmit" icon="el-icon-circle-check">提交</el-button>
    </template>
  </Dialog>
</template>

<script>
  import Dialog from './Dialog'
  import {
    createNamespacedHelpers
  } from 'vuex'
  const student = createNamespacedHelpers('student')
  const courses = createNamespacedHelpers('courses')

  export default {
    props: {
      value: {
        type: Boolean,
        required: true,
      },
      data: {
        type: Object,
      }
    },
    components: {
      Dialog,
    },
    data() {
      return {
        formValue: false,
        width: '40%',
        form: {
          studentList: [],
        },
      }
    },
    computed: {
      ...student.mapGetters(['allStudents']),
      ...courses.mapGetters(['currentStudents']),
      title() {
        return '课程管理-添加学生'
      },
      canSubmit() {
        return this.form.studentList.length
      },
      activeStudents() {
        let idList = this.currentStudents.map(x => x.id)
        let newList = this.allStudents.filter(x => {
          return !idList.includes(x.id)
        })
        return newList
      },
    },
    watch: {
      value(v) {
        this._updateFormValue(v)
      },
      formValue(v) {
        this._emitChange(v)
      },
    },
    methods: {
      clearAll() {
        this.form = {
          studentList: [],
        }
      },
      _updateFormValue(v) {
        this.formValue = v
      },
      _emitChange(v) {
        this.$emit('input', v)
        if (!v) {
          this.clearAll()
        }
      },
      submit() {
        this.$emit('submit', this.form)
      },
    },
    mounted() {
      this._updateFormValue(this.value)
    },
  }
</script>

<style scoped>

</style>